import React, { useEffect, useState } from "react"
import * as Components from '../components'
import PerfectScrollbar from "react-perfect-scrollbar"
import { Container, Row, Col, Tab, Nav } from "react-bootstrap"

const Solutions = () => {
  const isBrowser = typeof window !== "undefined"
  const [key, setKey] = useState('first');

  useEffect(() => {
    let isLoad = true
    if (isLoad && isBrowser) {
      const t = window.localStorage.getItem('isSolutions')
      setKey(t)
    }
    return () => {
      isLoad = false
    }
  }, [isBrowser])

  const isClick = e => setKey(e);

  return (
    <Components.Layout className="page-solutions" isClick={e => isClick(e)}>
      <Container fluid={true}>
        <Tab.Container
          defaultActiveKey={key}
          activeKey={key}
          onSelect={(k) => setKey(k)}
          id="solutions"
        >
          <Row>
            <Col className="d-none d-md-block" md={4} lg={3} >
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    Secure Facilitated Border Clearance
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    Forensic Law Enforcement
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Pre-Emptive Surveillance</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Airport Services</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">Secure Value Exchange</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={8} lg={5}>
              <Tab.Content>
                <h2 className="d-none d-md-block">SOLUTIONS</h2>
                <Tab.Pane eventKey="first">
                  <PerfectScrollbar>
                  <h2 className="d-block d-lg-none"> Secure Facilitated Border Clearance</h2>
                    <p>
                      Secure Facilitated Border Clearance Effective border
                      clearance means a safe and secure nation. It is one of the
                      key frontline components of protecting a nation's critical
                      infrastructure!<br></br> <br></br>
                      To strengthen and facilitate border clearance, the
                      international community introduced the ePassport carrying
                      a trusted face recognition compliant, "face-print of the
                      rightful holder".
                      <br></br> <br></br>
                      Deployment of ePassport reading and face recognition
                      technology in arrival and departure controls ensures vital
                      details can be machine-read from the contactless computer
                      chip embedded in the ePassport while face recognition
                      confirms the presenter as the "rightful holder".....
                      collectively in less than 5-seconds!
                      <br></br> <br></br>
                      Emirates FR's unique border clearance offering comprises:
                      <br></br> <br></br>
                    </p>
                    <ul>
                      <li>
                        <Components.Icon icon="circle" color="white" size="0.3em" />
                        Smart face biometric capture cameras to automatically
                        capture best quality, best pose face- prints from all
                        persons (simultaneously) at an Immigration Counter;
                      </li>
                      <li>
                        <Components.Icon icon="circle" color="white" size="0.3em" />
                        Face Biometric Capture Controllers to dynamically adjust
                        the camera to realise the highest quality, most detailed
                        face-prints while performing ePassport Rightful Holder
                        MATCH and WHITE List Checks;
                      </li>
                      <li>
                        <Components.Icon icon="circle" color="white" size="0.3em" />
                        High-Demand Face Recognition Engines to carry out
                        LOOKOUT Check(s); and a Face Biometric Store to
                        integrate face-prints from trusted sources and provide
                        real-time up-to-date copies to High-Demand Face
                        Recognition EnginesEmirates FR also offers a unique,
                        ePassport-compliant Immigration eGate complete with
                        WEB-Service connection to a Remote Adjudication
                        Workstation to allow Border Control Authorities to offer
                        eGate clearance to all ePassport holders upon arrival.
                      </li>
                    </ul>
                    <p>
                      <br></br> <br></br>
                      Provision of a turnkey bespoke Border Clearance
                      Infrastructures is also offered.
                      <br></br> <br></br>
                      Emirates FR 's Face Recognition Infrastructures are fault
                      tolerant and fully scalable to accommodate the unique
                      needs of each Border Control Authority.
                    </p>
                  </PerfectScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <PerfectScrollbar>
                    <h2 className="d-block d-lg-none"> Forensic Law Enforcement</h2>
                    <p>
                      One of the greatest challenges law enforcement faces is
                      identifying a person based on forensic evidence. Face
                      recognition technology serves as an invaluable tool to
                      address that need, allowing voluminous databases of
                      face-prints to be checked and sorted into an ordered list
                      of individuals that look most similar to the person law
                      enforcement is attempting to identify.
                      <br></br> <br></br>
                      Emirates FR offers a comprehensive infrastructure that
                      allows millions of face-prints to be searched in seconds.
                      That infrastructure includes,
                      <br></br> <br></br>
                    </p>
                    <ul>
                      <li>
                        <Components.Icon icon="circle" color="white" size="0.3em" />
                        Forensic Workstations to extract images from a variety
                        of media (e.g. image files, videos and printed
                        photographs) for comparison against one or more
                        face-print databases;
                      </li>
                      <li>
                        <Components.Icon icon="circle" color="white" size="0.3em" />
                        Bespoke face-print adjustment tools to allow the very
                        best images to be isolated for use in a search. Tools
                        include, "digital de-rotation of facial images" and
                        "exaggerated expression removal"; and
                      </li>
                      <li>
                        <Components.Icon icon="circle" color="white" size="0.3em" />
                        Face Recognition Engines to carry out fast-searches
                        against a database of face-prints and report a ranked
                        gallery of persons deemed to look most similar to the
                        enquiry image.
                      </li>
                    </ul>
                    <p>
                      <br></br> <br></br>
                      Searches of face-print databases using Emirates FR
                      Forensic Face Recognition Engines can be completed in
                      seconds: for example, a search of 15-million face-prints
                      can be completed in less than 1-seconds! All Emirates FR
                      Forensic Face Recognition Infrastructures are fault
                      tolerant and fully scalable.
                    </p>
                  </PerfectScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <PerfectScrollbar>
                  <h2 className="d-block d-lg-none"> Pre-Emptive Surveillance</h2>
                    <p>
                      To meet national security needs, responsible Governments
                      are deploying unified national infrastructures to
                      proactively protect critical infrastructure and key assets
                      vital to public health and safety, national security,
                      governance, economy and public confidence.
                      <br></br> <br></br>
                      For a unified national infrastructure to be truly
                      effective it must be pre-emptive; meaning it must support
                      immediate detection of threats and, wherever possible,
                      prevent them from occurring!
                      <br></br> <br></br>
                      Video surveillance has traditionally been used as the
                      mainstay for such infrastructures. Unfortunately, video
                      surveillance relies largely on operators to scan banks of
                      displays to detect issues. With research indicating that
                      the ability of even a well-motivated operator to
                      concentrate on a display drops by 90% after just
                      20-minutes, use of video surveillance on its own is
                      insufficient for creating an effective unified national
                      infrastructure.
                      <br></br> <br></br>
                      Building on expensive experiences creating face
                      recognition systems that use live video to detect, track
                      and identify persons in challenging real-world settings,
                      Emirates FR has created a technology infrastructure
                      capable of delivering pre-emptive surveillance using
                      digital IP cameras offering sufficient quality and
                      resolution to enable effective visual and forensic
                      assessment of video in both real-time and playback;
                      including performing face recognition; "intelligent
                      analytics technology" to process real-time and recalled
                      video and audio to automatically detect threats; and
                      analytics generated alerts to advise and activate
                      appropriate response resources.
                      <br></br> <br></br>
                      Emirates FR Pre-Emptive Surveillance technology and
                      integrated solutions ensure that critical infrastructure
                      and key assets can finally be protected in a manner where
                      potential threats are immediately detected and, wherever
                      possible, prevented!
                    </p>
                  </PerfectScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <PerfectScrollbar>
                  <h2 className="d-block d-lg-none">Airport Services</h2>
                    <p>
                      Airport Operators are continuously called upon to
                      accommodate a voluminous passenger traffic increase in a
                      manner that ensures facilitation and enhancement of the
                      overall passenger experience within the highest standards
                      of safety and security. Accommodation must be underpinned
                      by a comprehensive strategic vision: one that is global in
                      scope, innovative in approach and enabled by efficient
                      business processes and advanced technologies. Overall,
                      Operators must ensure that a balance is realised between
                      passenger facilitation and safety and security.
                      <br></br> <br></br>A constant challenge to achieving that
                      balance is confirming a passenger’s true identity. Use of
                      biometric technology has become synonymous with achieving
                      that confirmation.
                      <br></br> <br></br>
                      Emirates FR believes that facial recognition should be the
                      "primary" identity confirmation technology used at
                      airports. That belief is rooted in the fact that the
                      overwhelming majority of international air travellers -
                      currently estimated to be over 635,000,000 – carry the
                      globally standardised ePassport; each of which carries a
                      facial biometric (face-print) of the Rightful Holder and
                      machine-readable information vital to confirming the
                      authenticity of the passport and all data carried. That
                      belief is strengthened and focused by the realisation that
                      persons carrying an ePassport are, in effect, enrolled in
                      a “common global passenger clearance and facilitation
                      program"; one that can be used to great advantage by an
                      Airport Operator.
                      <br></br> <br></br>
                      While Operators realise significant indirect benefits from
                      ePassport-enabled border clearance, the existence of a
                      globally standardised machine-readable credential (the
                      ePassport) and retrievable facial biometric sets the stage
                      for introduction of a broad range of additional
                      machine/biometric assisted airport services; each of which
                      is designed to meet specific operational and/or commercial
                      needs of the airport. These services, which are
                      underpinned by the ability to identify the passenger
                      unobtrusively and in largely uncontrolled settings via
                      face recognition, enable Airport Operators to introduce
                      new and innovative ways to realize a new level of secure
                      value exchange with their clients.
                      <br></br> <br></br>
                      All of the major passenger movement steps - check-in,
                      baggage processing, Immigration exit control, security
                      access, security screening, boarding, Immigration entry
                      control and baggage collection – can realize benefits. All
                      can be facilitated!
                      <br></br> <br></br>
                      Emirates FR offers these benefits as innovative,
                      self-funded (revenue-generating) services through a unique
                      <strong>
                        Build-Support-Renew (BSR) business partnership
                      </strong>
                      with the Airport Operator.
                      <br></br> <br></br>
                      The Operator realises a new significant sustainable source
                      of annual revenue linked directly to the airport’s ever
                      increasing passenger traffic volume.
                    </p>
                  </PerfectScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <PerfectScrollbar>
                    <h2 className="d-block d-lg-none">Secure Value Exchange</h2>
                    <p>
                      Most business transactions involve an exchange in which
                      each party gives up and receives something of value.
                      <br></br> <br></br>
                      To improve provision, many businesses are automating the
                      delivery of services. Some are using self-service
                      machines/kiosks (for example, Airport Check-In Kiosks,
                      Immigration eGates and Bank ATMs), while others are using
                      software applications to connect with personal and laptop
                      computers, tablets and mobile devices (e.g. mobile phones)
                      held by a recipient.
                      <br></br> <br></br>
                      To secure the exchange, service providers are looking to
                      biometric technology to confirm that the "rightful
                      recipient" is in fact requesting/receiving service
                      delivery. Equally, the "rightful recipient" is seeking
                      assurance that only they are able to receive the service
                      delivery.
                      <br></br> <br></br>
                      While all biometrics have the potential for application,
                      only face recognition is the most facilitating: it does
                      not require a portion of the business process to be
                      dedicated to capturing the biometric, nor does it require
                      the person to overtly participate. Face biometric capture
                      can be accomplished while other aspects of the business
                      process are taking place. Most important, face recognition
                      findings can be confirmed and adjudicated by an untrained
                      human; something the other biometrics fail to support.
                      <br></br> <br></br>
                      Having worked in the demanding areas of capture and
                      processing of facial biometrics for border clearance and
                      forensic surveillance, Emirates FR has developed a suite
                      of technologies that enable service providers to achieve
                      secure value exchange when using self-service
                      machines/kiosks.
                    </p>
                  </PerfectScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </Components.Layout>
  )
}

export default Solutions
